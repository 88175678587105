import React, { useMemo, useState } from "react";
import { motion } from "framer-motion";
import profile from "../../../assets/social_icons/profile.png";
import generateVcard from "../../../functions/generateVcard";
import iziiFondo from "../../../assets/templates/izzi/izziFondo.png";
import izzi1 from "../../../assets/templates/izzi/izzi1.png";
import izzi2 from "../../../assets/templates/izzi/izzi2.png";
import izzi3 from "../../../assets/templates/izzi/izzi3.png";
import izziLogo from "../../../assets/templates/izzi/izziLogo.png";
import contact from "../../../assets/social_icons/contact.png";
import phoneImg from "../../../assets/social_icons/phone.png";
import whatsapp from "../../../assets/social_icons/whatsapp.png";
import linkedinImg from "../../../assets/social_icons/linkedin.png";
import email_ from "../../../assets/social_icons/email.png";
import facebookp from "../../../assets/social_icons/facebook.png";
import instagramp from "../../../assets/social_icons/instagram.png";
import twitterp from "../../../assets/social_icons/xlogo.png";
import videop from "../../../assets/social_icons/video.png";
import microsoftteamslg from "../../../assets/social_icons/microsoftteams.png";
import power_logo from "../../../assets/social_icons/powerpoint.png";
import word_logo from "../../../assets/social_icons/word.png";
import excel_logo from "../../../assets/social_icons/excel.png";
import pdf_logo from "../../../assets/social_icons/pdf.png";
import QrModal from "../../../components/QrModal/QrModal";

const MainCointainer = ({ user, typeOfDevice, projectLogo, project_name }) => {
  const {
    name,
    lastname,
    email,
    phone,
    linkedin,
    photo,
    facebook,
    instagram,
    twitter,
    job,
    video,
    microsoftteams,
    animated,
    excel,
    powerpoint,
    pdf,
    word,
  } = user;
  const [showQr, setShowQr] = useState(false);
  const url = useMemo(() => {
    const data = generateVcard(user);
    return data;
  }, [user]);
  const handleQR = () => {
    setShowQr(!showQr);
  };
  const socialStyle = "flex justify-center drop-shadow-xl mt-2";
  const labelStyle = "mt-3 text-center sm:text-sm xl:text-base font-body";

  return (
    <div className="max-w-full h-screen relative ">
      <div className="relative h-1/4 md:h-1/2 lg:h-auto mb-8  md:mt-14">
        <img src={iziiFondo} alt="iziiFondo" className="w-full h-full" />

        <div className="absolute top-10 left-10 md:top-20 lg:top-10 lg:left-24 w-[80%] h-full z-30 ">
          <div className="relative flex justify-center items-center w-1/4">
            <QrModal
              showQr={showQr}
              setShowQr={setShowQr}
              url={window.location.href}
              fullname={`${name} ${lastname}`}
            />
            <img
              className=" sm:w-20 sm:h-20 md:w-32 md:h-32 lg:w-40 lg:h-40 xl:w-56 xl:h-56 rounded-full p-[2px] lg:p-2 bg-izzi-primary"
              src={photo ? photo : profile}
              alt="profile"
            />
            <div className="absolute left-10 top-6 sm:left-16 sm:top-8 md:left-28 md:top-16 lg:left-40 lg:top-12 xl:left-56 xl:top-20 w-12 h-12  sm:w-16 sm:h-16 md:w-20 md:h-20 lg:w-28 lg:h-28 xl:w-36 xl:h-36 rounded-full bg-izzi-primary flex justify-center items-center">
              <motion.button
                onClick={handleQR}
                title="Ver QR"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                style={{ filter: "none" }}
                className="relative flex flex-col items-center  justify-center  rounded-full "
              >
                <svg
                  fill="none"
                  stroke="#ffff"
                  className="w-8 h-8 sm:w-10 sm:h-10 md:w-14 md:h-14 lg:w-16 lg:h-16 xl:w-24 xl:h-24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="#ffff"
                    stroke="none"
                    d="M19.125 15.75h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"
                  ></path>
                  <path
                    fill="#ffff"
                    stroke="none"
                    d="M15.375 12.75h-2.25a.375.375 0 0 0-.375.375v2.25c0 .207.168.375.375.375h2.25a.375.375 0 0 0 .375-.375v-2.25a.375.375 0 0 0-.375-.375Z"
                  ></path>
                  <path
                    fill="#ffff"
                    stroke="none"
                    d="M22.125 19.5h-2.25a.375.375 0 0 0-.375.375v2.25c0 .207.168.375.375.375h2.25a.375.375 0 0 0 .375-.375v-2.25a.375.375 0 0 0-.375-.375Z"
                  ></path>
                  <path
                    fill="#ffff"
                    stroke="none"
                    d="M22.125 12.75h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375Z"
                  ></path>
                  <path
                    fill="#ffff"
                    stroke="none"
                    d="M14.625 20.25h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375Z"
                  ></path>
                  <path
                    fill="#ffff"
                    stroke="none"
                    d="M19.125 4.5h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"
                  ></path>
                  <path d="M21 2.25h-6.75a.75.75 0 0 0-.75.75v6.75c0 .414.336.75.75.75H21a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75Z"></path>
                  <path
                    fill="#ffff"
                    stroke="none"
                    d="M7.875 4.5h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"
                  ></path>
                  <path d="M9.75 2.25H3a.75.75 0 0 0-.75.75v6.75c0 .414.336.75.75.75h6.75a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75Z"></path>
                  <path
                    fill="#ffff"
                    stroke="none"
                    d="M7.875 15.75h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"
                  ></path>
                  <path d="M9.75 13.5H3a.75.75 0 0 0-.75.75V21c0 .414.336.75.75.75h6.75a.75.75 0 0 0 .75-.75v-6.75a.75.75 0 0 0-.75-.75Z"></path>
                </svg>
              </motion.button>
            </div>
          </div>
        </div>

        {/* Div para la imagen Izzi1 */}
        <div className="absolute top-4  right-4 xl:right-10 2xl:top-10 2xl:right-14 z-20 flex items-end justify-end ">
          <img
            src={izziLogo}
            alt="izziLogo"
            className="w-[40%] md:w-3/4 xl:w-full"
          />
        </div>

        <div className="absolute left-0 top-0  xl:left-0 z-20">
          <img
            src={izzi1}
            alt="izzi1"
            className="w-[40%] md:w-1/2 lg:w-3/4 xl:w-full"
          />
        </div>

        {/* Div posicionado abajo a la derecha */}
        <div className="absolute bottom-5 sm:bottom-4 md:bottom-10 lg:bottom-2 right-0 z-20 flex items-end justify-end">
          <img
            src={izzi2}
            alt="izzi2"
            className="w-[30%] sm:w-[30%] md:w-1/2 lg:w-3/4  xl:w-full"
          />
        </div>
        <div className="absolute bottom-11 sm:bottom-12 md:bottom-20 lg:bottom-20 xl:bottom-28 right-0 z-20 flex items-end justify-end">
          <img
            src={izzi3}
            alt="izzi2"
            className="w-[25%] sm:w-[30%] md:w-1/2 lg:w-3/4 xl:w-full"
          />
        </div>

        <div className=" md:absolute md:bottom-[-20px] lg:bottom-[-30px] 2xl:bottom-0 md:left-20 xl:z-20">
          <div className="leading-tight flex flex-col justify-center items-center space-y-3 lg:space-x-0 lg:mt-0 max-w-[100%] md:max-w-full">
            <motion.h1
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeInOut", delay: 0.8 }}
              className="text-xl md:text-2xl lg:text-4xl xl:text-4xl  text-center break-words "
            >
              {name} {lastname}
            </motion.h1>

            {job && (
              <motion.h2
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: "easeInOut", delay: 1 }}
                style={{ fontFamily: "voestalpine-ligth" }}
                className="text-base md:text-xl lg:text-2xl  xl:text-2xl   text-center uppercase"
              >
                {job}
              </motion.h2>
            )}
          </div>
        </div>
      </div>

      <div className="w-full flex justify-center items-center">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, ease: "easeInOut", delay: 1.6 }}
          className="flex flex-row flex-wrap justify-center items-center pt-14 lg:pt-10f :pt-14 pb-10 px-2  w-full space-x-4 xl:w-3/4  lg:space-x-20"
        >
          <a
            className={`flex flex-col items-center  ${socialStyle}`}
            href={"tel:" + phone}
          >
            <div></div>
            <motion.img
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 400, damping: 17 }}
              src={phoneImg}
              alt="Teléfono"
              className="ml-4"
            />
            <p className={labelStyle}>Teléfono</p>
          </a>
          <a
            className={`${socialStyle} ${
              animated?.whats_animated ? "animate-bounce" : ""
            } flex flex-col items-center `}
            href={"https://wa.me/" + phone}
          >
            <div></div>
            <motion.img
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 400, damping: 17 }}
              src={whatsapp}
              alt="Whatsapp"
            />
            <p className={labelStyle}>WhatsApp</p>
          </a>
          <a
            className={`flex flex-col items-center  ${socialStyle}`}
            href={URL.createObjectURL(url)}
            download={`${String(name + lastname)
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")}.vcf`}
          >
            <motion.img
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 400, damping: 17 }}
              src={contact}
              alt="Contacto"
            />
            <p className={labelStyle}>Contacto</p>
          </a>
          <a
            className={`flex flex-col items-center  ${socialStyle}`}
            href={"mailto:" + email}
          >
            <motion.img
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 300, amping: 17 }}
              src={email_}
              width={100}
              alt="Email"
            />
            <p className={labelStyle}>Email</p>
          </a>

          {linkedin && (
            <a
              className={`flex flex-col items-center my-2 ${socialStyle}`}
              href={
                linkedin.includes("https://www.linkedin.com/in")
                  ? linkedin
                  : "https://www.linkedin.com/in/" + linkedin
              }
            >
              <motion.img
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                src={linkedinImg}
                alt="Linkedin"
              />
              <p className={labelStyle}>LinkedIn</p>
            </a>
          )}
          {facebook && (
            <a
              className={`flex flex-col items-center my-2 ${socialStyle}`}
              href={
                typeOfDevice === "Android"
                  ? facebook.includes("https://www.facebook.com/")
                    ? `fb://facewebmodal/f?href=${facebook}`
                    : `fb://facewebmodal/f?href=https://www.facebook.com/${facebook}/`
                  : typeOfDevice === "iOS"
                  ? facebook.includes("https://www.facebook.com/")
                    ? `fb://profile/${facebook.replace(
                        "https://www.facebook.com/",
                        ""
                      )}`
                    : `fb://profile/${facebook}`
                  : facebook.includes("https://www.facebook.com/")
                  ? `${facebook}`
                  : `https://www.facebook.com/${facebook}/`
              }
            >
              <motion.img
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                src={facebookp}
                alt="Facebook"
              />
              <p className={labelStyle}>Facebook</p>
            </a>
          )}
          {instagram && (
            <a
              className={`flex flex-col items-center my-2 ${socialStyle}`}
              href={
                instagram.includes("https://www.instagram.com/")
                  ? `${instagram}`
                  : `https://instagram.com/${instagram}`
              }
            >
              <motion.img
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                src={instagramp}
                alt="Instagram"
              />
              <p className={labelStyle}>Instagram</p>
            </a>
          )}
          {twitter && (
            <a
              className={`flex flex-col items-center my-2 ${socialStyle}`}
              href={
                twitter.includes("https://twitter.com/")
                  ? `${twitter}`
                  : `https://twitter.com/${twitter}`
              }
            >
              <motion.img
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                src={twitterp}
                alt="Twitter"
              />
              <p className={labelStyle}>X (Twitter)</p>
            </a>
          )}
          {video && (
            <a
              className={`flex flex-col items-center my-2 ${socialStyle}`}
              href={`${video}`}
              target="_blank"
              rel="noreferrer"
            >
              <motion.img
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                src={videop}
                alt="VIDEO"
              />
              <p className={labelStyle}>VIDEO</p>
            </a>
          )}
          {microsoftteams && (
            <a
              className={`flex flex-col items-center my-2 ${socialStyle}`}
              href={
                microsoftteams.includes("https://teams.microsoft.com/l/")
                  ? microsoftteams
                  : "https://teams.microsoft.com/l/" + microsoftteams
              }
              target="_blank"
              rel="noreferrer"
            >
              <motion.img
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                src={microsoftteamslg}
                alt="Microsoft Teams"
              />
              <p className={labelStyle}>MS Teams</p>
            </a>
          )}
          {word && (
            <a
              className={`flex flex-col items-center my-2 ${socialStyle}`}
              href={word}
              target="_blank"
              rel="noreferrer"
            >
              <motion.img
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                src={word_logo}
                alt="Word"
              />
              <p className={labelStyle}>Word</p>
            </a>
          )}
          {powerpoint && (
            <a
              className={`flex flex-col items-center my-2 ${socialStyle}`}
              href={powerpoint}
              target="_blank"
              rel="noreferrer"
            >
              <motion.img
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                src={power_logo}
                alt="Power Point"
              />
              <p className={labelStyle}>Power Point</p>
            </a>
          )}
          {excel && (
            <a
              className={`flex flex-col items-center my-2 ${socialStyle}`}
              href={excel}
              target="_blank"
              rel="noreferrer"
            >
              <motion.img
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                src={excel_logo}
                alt="Excel"
              />
              <p className={labelStyle}>Excel</p>
            </a>
          )}
          {pdf && (
            <a
              className={`flex flex-col items-center my-2 ${socialStyle}`}
              href={pdf}
              target="_blank"
              rel="noreferrer"
            >
              <motion.img
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                src={pdf_logo}
                alt="PDF"
              />
              <p className={labelStyle}>PDF</p>
            </a>
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default MainCointainer;
