import { Link } from "react-router-dom";

const HeaderLogin = ({ user }) => {
  return (
    <header className="flex flex-row items-center justify-end px-4 py-2 md:absolute top-0 right-0 w-full bg-izzi-primary">
      <span className="text-sm text-white mx-2">Iniciar Sesión</span>
      <Link
        to={user?.projectId ? `/${user?.projectId}` : "/"}
        className="p-2 bg-izzi-primary rounded-full cursor-pointer hover:bg-opacity-80 transition duration-300 ease-in-out"
      >
        <svg
          width="20"
          height="20"
          fill="#fff"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M11 7 9.6 8.4l2.6 2.6H2v2h10.2l-2.6 2.6L11 17l5-5-5-5Zm9 12h-8v2h8c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-8v2h8v14Z"></path>
        </svg>
      </Link>
    </header>
  );
};

export default HeaderLogin;
