import React, { useContext, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link, useParams } from "react-router-dom";
import backtoa from "../../assets/img/return.svg";
import profile from "../../assets/social_icons/profile.png";
import { FirebaseFuntions } from "../../context/FirebaseContext";
import { Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import logo from "../../assets/makinn/makinn.png";
import { Input, Checkbox } from "antd";
import xlogo from "../../assets/social_icons/xlogo.png";
import web_logo from "../../assets/social_icons/web.png";
import linkedid_logo from "../../assets/social_icons/linkedin.png";
import instagram_logo from "../../assets/social_icons/instagram.png";
import facebook from "../../assets/social_icons/facebook.png";
import video_logo from "../../assets/social_icons/video.png";
import msteams_logo from "../../assets/social_icons/microsoftteams.png";
import powerpoint_logo from "../../assets/social_icons/powerpoint.png";
import word_logo from "../../assets/social_icons/word.png";
import excel_logo from "../../assets/social_icons/excel.png";
import pdf_logo from "../../assets/social_icons/pdf.png";
import ra_logo from "../../assets/social_icons/ra.png";
import trash from "../../assets/img/trash.svg";
import { CardSocial } from "../../components/CardSocial/CardSocial.jsx";

const EditProfile = () => {
  const {
    loginFlag,
    authStatus,
    searchUser,
    uploadPhoto,
    updateUser,
    authID,
    deleteSocial,
    uploadBanner,
    newFilePower,
    newFileWord,
    newFileExcel,
    newFilePdf,
    deleteFile,
  } = useContext(FirebaseFuntions);

  const { projectId, userId } = useParams();
  const [user, setUser] = useState("");
  const [loader, setLoader] = useState(false);
  const [photo, setPhoto] = useState({});
  const [photoFile, setPhotoFile] = useState(null);
  const [bannerFile, setBannerFile] = useState(null);
  const [socials, setSocials] = useState({});
  const [isBig, setIsBig] = useState(false);
  const [alertStatus, setAlertStatus] = useState("");
  const [updateAlert, setUpdateAlert] = useState(false);
  const [isPersonal, setIsPersonal] = useState(false);
  const [typeOfF, setTypeOfF] = useState("default");
  const [isSidebar, setIsSidebar] = useState("editar_cuenta");
  const [activeItem, setActiveItem] = useState("editar_cuenta");
  const [animatedBehavior, setAnimatedBehavior] = useState(null);
  const textDisabled = "Disponible con tema 'Default'";

  const optionTemplate = [
    { value: "promo", label: "Default" },
    { value: "luxury", label: "Elegante" },
    {
      value: "bh",
      label: "BH Trade Market",
      condition: () => user?.projectId === "bh" || user?.role === "superadmin",
    },
    {
      value: "voestalpine",
      label: "Voestalpine",
      condition: () => user?.projectId === "voestalpine",
    },
    {
      value: "wen",
      label: "Wen Rodríguez",
      condition: () =>
        user?.projectId === "makinn" && user?.userId === "usuario_0",
    },
    {
      value: "carnot",
      label: "Carnot",
      condition: () => user?.projectId === "carnot",
    },
    {
      value: "procaps",
      label: "Procaps",
      condition: () => user?.projectId === "procaps",
    },
    {
      value: "ollamani",
      label: "Ollamani",
      condition: () => user?.projectId === "ollamani",
    },
    {
      value: "oxxo",
      label: "Oxxo",
      condition: () => user?.projectId === "oxxo",
    },
    {
      value: "custlog",
      label: "Custlog",
      condition: () => user?.projectId === "custlog",
    },
    {
      value: "izzi",
      label: "IZZI",
      condition: () => user?.projectId === "izzi",
    },
  ];

  const filteredOptions = optionTemplate.filter((option) => {
    return option.condition ? option.condition() : true;
  });

  const fetchData = async (userId, projectId, searchUser) => {
    if (userId || projectId) {
      try {
        const data = await searchUser(userId, projectId);

        if (data) {
          setUser(data);
          setIsPersonal(data.footer === "true");
          setTypeOfF(data.typeoffooter || "default");
          setSocials({
            facebook: data.facebook,
            instagram: data.instagram,
            linkedin: data.linkedin,
            twitter: data.twitter,
            web: data.web,
            video: data.video,
            microsoftteams: data.microsoftteams,
            augmented_reality: data.augmented_reality,
          });

          setPhotoFile(data.photo || null);
          setBannerFile(data.banner || null);
          setAnimatedBehavior(data.animated || false);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  };

  useEffect(() => {
    fetchData(userId, projectId, searchUser);
  }, [userId, projectId, searchUser]);

  const handleItemClick = (item) => {
    setIsSidebar(item);
    setActiveItem(item);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const datos = Array.from(new FormData(e.target));
    const obj = Object.fromEntries(datos);
    // console.log(obj);
    if (!isBig && photo.size !== 0) {
      const respuesta = updateUser({ ...obj, projectId, userId });
      respuesta.then((res) => {
        // console.log(res)
        setAlertStatus(res);
        handleAlert();
        //actualiza el estado de la información del usuario en el componente
        const data = searchUser(userId, projectId);
        data.then((res) => {
          setUser(res);
          document.getElementById("template").value = res.template;
          document.getElementById("location").value = res.location;
        });
      });
    } else {
      const respuesta = updateUser({ ...obj, projectId, userId });
      respuesta.then((res) => {
        console.log(res);
        setAlertStatus(res);
        handleAlert();
      });
    }
    const newSocials = (obj) => {
      const newSocials = { ...socials };
      if (obj.facebook) {
        newSocials.facebook = obj.facebook;
      }
      if (obj.microsoftteams) {
        newSocials.microsoftteams = obj.microsoftteams;
      }
      if (obj.instagram) {
        newSocials.instagram = obj.instagram;
      }
      if (obj.linkedin) {
        newSocials.linkedin = obj.linkedin;
      }
      if (obj.twitter) {
        newSocials.twitter = obj.twitter;
      }
      if (obj.web) {
        newSocials.web = obj.web;
      }
      if (obj.video) {
        newSocials.video = obj.video;
      }
      if (obj.augmented_reality) {
        newSocials.augmented_reality = obj.augmented_reality;
      }
      return newSocials;
    };
    setSocials(newSocials(obj));
    e.target.reset();
  };

  const handleLoader = () => {
    setTimeout(() => {
      setLoader(true);
    }, 2000);
  };

  const handleBanner = (e) => {
    const extData = String(e.type).replace("image/", ".");
    const url = URL.createObjectURL(e);
    if (e.size > 2097152) {
      setIsBig(true);
    } else {
      setIsBig(false);
      setBannerFile(url);
      const resultado = uploadBanner(
        e,
        userId + "banner" + extData,
        userId,
        projectId
      );
      resultado.then((res) => {
        setAlertStatus(res);
        handleAlert();
      });
    }
    return false;
  };

  const handleUpload = (e) => {
    setPhoto(e);
    const extData = String(e.type).replace("image/", ".");
    const url = URL.createObjectURL(e);
    if (e.size > 2097152) {
      setIsBig(true);
    } else {
      setIsBig(false);
      setPhotoFile(url);
      const resultado = uploadPhoto(
        e,
        userId + "photo" + extData,
        userId,
        projectId
      );
      resultado.then((res) => {
        setAlertStatus(res);
        handleAlert();
      });
    }
    return false;
  };

  const handleAlert = () => {
    setUpdateAlert(true);
    setTimeout(() => {
      return setUpdateAlert(false);
    }, 5000);
  };

  const handleDelete = (social) => {
    const datos = { ...{ social: social }, userId, projectId };
    const respuesta = deleteSocial(datos);
    respuesta.then((res) => {
      setAlertStatus(res);
      handleAlert();
      fetchData(userId, projectId, searchUser);
    });
    if (datos.social === "banner") {
      setBannerFile(null);
    }
  };

  useEffect(() => {
    authStatus();
    handleLoader();
  }, [authStatus]);

  const handlePowePoint = (e) => {
    const file = e;
    const originalName = file.name;
    const fileName = originalName;
    if (e.size > 2097152) {
      setIsBig(true);
    } else {
      setIsBig(false);
      const resultado = newFilePower(file, fileName, projectId, userId);
      resultado.then((res) => {
        setAlertStatus(res);
        handleAlert();
        fetchData(userId, projectId, searchUser);
      });
    }
    return false;
  };

  const handleWord = (e) => {
    const file = e;
    const originalName = file.name;
    const fileName = originalName;
    if (e.size > 2097152) {
      setIsBig(true);
    } else {
      setIsBig(false);
      const resultado = newFileWord(file, fileName, projectId, userId);
      resultado.then((res) => {
        setAlertStatus(res);
        handleAlert();
        fetchData(userId, projectId, searchUser);
      });
    }
    return false;
  };

  const handleExcel = (e) => {
    const file = e;
    const originalName = file.name;
    const fileName = originalName;
    if (e.size > 2097152) {
      setIsBig(true);
    } else {
      setIsBig(false);
      const resultado = newFileExcel(file, fileName, projectId, userId);
      resultado.then((res) => {
        setAlertStatus(res);
        handleAlert();
        fetchData(userId, projectId, searchUser);
      });
    }
    return false;
  };

  const handlePdf = (e) => {
    const file = e;
    const originalName = file.name;
    const fileName = originalName;
    if (e.size > 2097152) {
      setIsBig(true);
    } else {
      setIsBig(false);
      const resultado = newFilePdf(file, fileName, projectId, userId);
      resultado.then((res) => {
        setAlertStatus(res);
        handleAlert();
        fetchData(userId, projectId, searchUser);
      });
    }
    return false;
  };

  const handleDeleteFile = (file) => {
    const datos = { file: file, userId, projectId };
    const respuesta = deleteFile(datos);
    respuesta.then((res) => {
      setAlertStatus(res);
      handleAlert();
      fetchData(userId, projectId, searchUser);
    });
  };

  return (
    <>
      <AnimatePresence>
        {updateAlert && (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={`w-full bg-green-500 p-2 fixed top-0 flex justify-center items-center z-50`}
          >
            <span className="text-md text-white font-medium uppercase text-center w-full">
              {alertStatus}
            </span>
          </motion.div>
        )}
      </AnimatePresence>
      <>
        {loginFlag ? (
          loader && authID === user.authID ? (
            <>
              <div className="sm:px-5 md:px-44 mt-3">
                <Link
                  to={`/${projectId}/${userId}`}
                  className="flex items-center"
                >
                  <img
                    src={backtoa}
                    alt="flecha de retorno"
                    className="w-8 h-8 mr-2"
                  />
                  <span className="uppercase text-sm">Regresar</span>
                </Link>
              </div>
              <motion.div
                className="flex sm:flex-col md:flex-row md:w-full sm:w-full sm:h-auto md:h-screen sm:px-5 sm:pb-3 md:pb-16 md:px-44"
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  default: {
                    duration: 0.4,
                    ease: [0, 0.71, 0.2, 1.01],
                  },
                  opacity: {
                    duration: 0.4,
                  },
                }}
              >
                <div className="bg-black md:w-4/12 sm:w-full md:p-10 sm:p-6 border-radius-5-l shadow-xl">
                  <img
                    src={photoFile ? photoFile : profile}
                    alt="foto de perfil"
                    className="w-36 h-36 rounded-full mx-auto border-2 border-makinn_primary"
                  />
                  <h1 className="text-white text-center text-2xl font-semibold capitalize w-auto break-words mt-5">
                    {user.name} {user.lastname}
                  </h1>
                  <div className="mt-5 divide-y-2 divide-solid">
                    <button
                      className={`${
                        activeItem === "editar_cuenta"
                          ? "text-white font-bold bg-makinn_primary p-4 text-lg rounded-t-lg"
                          : "text-white"
                      } w-full flex items-center hover:bg-makinn_primary p-4 text-lg rounded-t-lg`}
                      onClick={() => handleItemClick("editar_cuenta")}
                    >
                      <svg
                        className="mr-3"
                        width="40"
                        height="40"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M20 16.5V21a1 1 0 0 1-1 1h-3.25"></path>
                        <path d="M20 8V3a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h3"></path>
                        <path d="M8 8h7"></path>
                        <path d="M11.5 22 20 11.5"></path>
                        <path d="M8 12h4"></path>
                      </svg>
                      Editar Cuenta
                    </button>
                    <button
                      className={`${
                        activeItem === "Datos de Contacto"
                          ? "text-white font-bold bg-makinn_primary p-4 text-lg"
                          : "text-white"
                      } w-full flex items-center hover:bg-makinn_primary p-4 text-lg`}
                      onClick={() => handleItemClick("Datos de Contacto")}
                    >
                      <svg
                        className="mr-3"
                        width="40"
                        height="40"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M19 2H5a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1Z"></path>
                        <path d="M12 9.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
                        <path d="M15 12.5a3 3 0 0 0-6 0"></path>
                        <path d="M9 15.5h6"></path>
                        <path d="M9 18.5h3.5"></path>
                      </svg>
                      Datos de Contacto
                    </button>
                    <button
                      className={`${
                        activeItem === "Documentos"
                          ? "text-white font-bold bg-makinn_primary p-4 text-lg"
                          : "text-white"
                      } w-full flex items-center hover:bg-makinn_primary p-4 text-lg`}
                      onClick={() => handleItemClick("Documentos")}
                    >
                      <svg
                        className="mr-3"
                        width="40"
                        height="40"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m19.275 6.975-5.25-5.25A.68.68 0 0 0 13.5 1.5H6c-.825 0-1.5.675-1.5 1.5v18c0 .825.675 1.5 1.5 1.5h12c.825 0 1.5-.675 1.5-1.5V7.5a.68.68 0 0 0-.225-.525ZM13.5 3.3l4.2 4.2h-4.2V3.3ZM18 21H6V3h6v4.5c0 .825.675 1.5 1.5 1.5H18v12Z"></path>
                        <path d="M16.5 16.5h-9V18h9v-1.5Z"></path>
                        <path d="M16.5 12h-9v1.5h9V12Z"></path>
                      </svg>
                      Documentos
                    </button>
                    <button
                      className={`${
                        activeItem === "Redes Sociales"
                          ? "text-white font-bold bg-makinn_primary p-4 text-lg rounded-b-lg"
                          : "text-white"
                      } w-full flex items-center hover:bg-makinn_primary p-4 text-lg rounded-b-lg`}
                      onClick={() => handleItemClick("Redes Sociales")}
                    >
                      <svg
                        className="mr-3"
                        width="40"
                        height="40"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M17.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"></path>
                        <path d="M6.5 14.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"></path>
                        <path d="m15 6.787-6.33 3.836"></path>
                        <path d="m8.67 13.282 6.67 3.942"></path>
                        <path d="M17.5 16a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Z"></path>
                      </svg>
                      Redes Sociales
                    </button>
                  </div>
                </div>
                <div
                  className="bg-white md:w-8/12 sm:w-full md:p-7 sm:p-5 border-radius-5 border border-black shadow-xl sm:overflow-hidden md:overflow-y-scroll
                                   [&::-webkit-scrollbar]:w-1 [&::-webkit-scrollbar-track]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:rounded-full
                                   [&::-webkit-scrollbar-thumb]:bg-black"
                >
                  <form className="w-full h-full" onSubmit={handleSubmit}>
                    {isSidebar === "editar_cuenta" && (
                      <>
                        <h2 className="text-black text-left text-lg my-3 mb-3 font-bold font-heading uppercase">
                          Editar cuenta
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-7">
                          <div>
                            <label
                              className="block mb-2 text-sm text-black font-medium text-left uppercase font-body"
                              htmlFor="file_input"
                            >
                              Subir Foto
                              <br />
                              <span className="text-[12px]">
                                (Cuida que la imagen sea cuadrada)
                              </span>
                            </label>
                            <span className="text-sm text-black font-medium uppercase">
                              {isBig && "El archivo es demasiado pesado"}
                            </span>
                            <ImgCrop
                              rotationSlider={true}
                              aspect={1}
                              quality={0.7}
                              modalTitle="Recorta tu imagen"
                              modalOk="Guardar"
                              modalCancel="Cancelar"
                            >
                              <Upload
                                showUploadList={false}
                                accept="image/png, image/jpeg, image/jpg"
                                beforeUpload={handleUpload}
                                className="w-full flex"
                              >
                                <Button
                                  icon={<UploadOutlined />}
                                  className="bg-white w-full font-body"
                                >
                                  Seleccionar imagen
                                </Button>
                              </Upload>
                            </ImgCrop>
                            <p
                              className="mt-1 text-sm text-black text-left font-body"
                              id="file_input_help"
                            >
                              PNG, JPG o JPGE (MAX. 2MB).
                            </p>
                          </div>
                          <div>
                            <label
                              className="block mb-2 text-sm text-black font-medium text-left uppercase font-body"
                              htmlFor="file_input"
                            >
                              Cambiar Logo
                              <br />
                              <span className="text-[12px]">
                                (Considera una imagen rectangular)
                              </span>
                            </label>
                            <span className="text-sm text-black font-medium uppercase">
                              {isBig && "El archivo es demasiado pesado"}
                            </span>
                            {bannerFile != null ? (
                              <div className="flex flex-row mr-4 items-center justify-between mx-auto w-full p-2 border-2 border-black rounded-lg">
                                <img
                                  src={bannerFile}
                                  alt="banner"
                                  className="md:w-[230px] xs:w-[200px]"
                                />
                                <button
                                  className={`text-sm font-medium contents ${
                                    bannerFile ? "block" : "hidden"
                                  }`}
                                  onClick={() => handleDelete("banner")}
                                >
                                  <img src={trash} alt="eliminar" />
                                </button>
                              </div>
                            ) : (
                              <ImgCrop
                                quality={0.4}
                                modalTitle="Recorta tu imagen"
                                modalOk="Guardar"
                                modalCancel="Cancelar"
                                aspect={15 / 2}
                                rotationSlider
                                aspectSlider
                                showReset
                                fillColor="transparent"
                              >
                                <Upload
                                  showUploadList={false}
                                  accept="image/png, image/jpeg, image/jpg"
                                  beforeUpload={handleBanner}
                                  className="w-full flex"
                                >
                                  <Button
                                    icon={<UploadOutlined />}
                                    className="bg-white w-full font-body"
                                  >
                                    Seleccionar logo
                                  </Button>
                                </Upload>
                              </ImgCrop>
                            )}
                            <p
                              className="mt-1 text-sm text-black text-left font-body"
                              id="file_input_help"
                            >
                              PNG, JPG o JPGE (MAX. 2MB).
                            </p>
                          </div>
                          <div>
                            <label
                              className="w-full block text-sm text-black font-medium text-left uppercase font-body"
                              htmlFor="template"
                            >
                              Selecciona un tema
                            </label>
                            <div className="relative">
                              <select
                                className="appearance-none w-full rounded-md border border-black py-2 px-3 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-makinn_primary sm:leading-6 focus:border-transparent focus:outline-none font-body"
                                id="template"
                                name="template"
                                defaultValue={user?.template}
                              >
                                {filteredOptions.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg
                                  width={20}
                                  height={20}
                                  fill="none"
                                  stroke="#000000"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="m6 9 6 6 6-6" />
                                </svg>
                              </div>
                            </div>
                          </div>
                          <div>
                            <label
                              className="w-full block text-sm text-black font-medium text-left uppercase font-body"
                              htmlFor="footer"
                            >
                              Pie de página
                            </label>
                            <div className="relative">
                              <select
                                className={`appearance-none w-full rounded-md border border-black py-2 px-3 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-makinn_primary sm:leading-6 focus:border-transparent focus:outline-none font-body ${
                                  user?.template !== "promo"
                                    ? "border border-gray-400 opacity-50"
                                    : ""
                                }`}
                                disabled={user?.template !== "promo"}
                                id="footer"
                                name="footer"
                                defaultValue={
                                  user?.template !== "promo" ? "" : user?.footer
                                }
                                onChange={(e) => {
                                  setIsPersonal(
                                    e.target.value === "true" ? true : false
                                  );
                                }}
                              >
                                {user?.template !== "promo" && (
                                  <option value="">{textDisabled}</option>
                                )}
                                <option value={true}>Si</option>
                                <option value={false}>No</option>
                              </select>
                              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg
                                  width={20}
                                  height={20}
                                  fill="none"
                                  stroke={
                                    user?.template !== "promo"
                                      ? "#9ca3af"
                                      : "#000000"
                                  }
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="m6 9 6 6 6-6" />
                                </svg>
                              </div>
                            </div>
                          </div>
                          {user?.template === "promo" && (
                            <>
                              <div
                                className={`${isPersonal ? "block" : "hidden"}`}
                              >
                                <label
                                  className="w-full block text-sm text-black font-medium text-left uppercase"
                                  htmlFor="footer"
                                >
                                  Tipo de texto personalizado
                                </label>
                                <div className="relative">
                                  <select
                                    className="appearance-none w-full rounded-md border border-black py-2 px-3 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-makinn_primary sm:leading-6 focus:border-transparent focus:outline-none font-body"
                                    id="typeoffooter"
                                    name="typeoffooter"
                                    defaultValue={user?.typeoffooter}
                                    onChange={(e) => {
                                      setTypeOfF(e.target.value);
                                    }}
                                  >
                                    <option value="defaul">Default</option>
                                    <option value="personalized">
                                      Texto personalizado
                                    </option>
                                  </select>
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                      width={20}
                                      height={20}
                                      fill="none"
                                      stroke="#000000"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      viewBox="0 0 24 24"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="m6 9 6 6 6-6" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${isPersonal ? "block" : "hidden"}`}
                              >
                                <label
                                  className="w-full block text-sm text-black font-medium text-left uppercase"
                                  htmlFor="footer"
                                >
                                  Texto personalizado
                                </label>
                                <input
                                  className={`appearance-none w-full rounded-md border border-black py-2 px-3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-makinn_primary sm:leading-6 focus:border-transparent focus:outline-none font-body ${
                                    typeOfF !== "personalized" &&
                                    "opacity-50 border border-gray-400"
                                  }`}
                                  type="text"
                                  id="footer_text"
                                  name="footer_text"
                                  placeholder="Texto personalizado"
                                  defaultValue={user?.footer_text}
                                  disabled={
                                    typeOfF === "personalized" ? false : true
                                  }
                                />
                              </div>
                            </>
                          )}
                          {projectId === "promo" && (
                            <div>
                              <label className="w-full block text-sm text-black font-medium text-left uppercase font-body">
                                Logo Promo Conecta
                              </label>
                              <div className="relative">
                                <select
                                  className="appearance-none w-full rounded-md border border-black py-2 px-3 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-makinn_primary sm:leading-6 focus:border-transparent focus:outline-none font-body"
                                  id="logos_promoconecta"
                                  name="logo_promoconecta"
                                  defaultValue={user?.logo_promoconecta}
                                >
                                  <option value="true">Si</option>
                                  <option value="false">No</option>
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                  <svg
                                    width={20}
                                    height={20}
                                    fill="none"
                                    stroke="#000000"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="m6 9 6 6 6-6" />
                                  </svg>
                                </div>
                              </div>
                            </div>
                          )}
                          {projectId === "procaps" && (
                            <div>
                              <label className="w-full block text-sm text-black font-medium text-left uppercase font-body">
                                Localización
                              </label>
                              <div className="relative">
                                <select
                                  className="appearance-none w-full rounded-md border border-black py-2 px-3 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-makinn_primary sm:leading-6 focus:border-transparent focus:outline-none font-body"
                                  id="location"
                                  name="location"
                                  defaultValue={user?.location}
                                >
                                  <option value={0}>Sin ubicación</option>
                                  <option value={1}>Guatemala</option>
                                  <option value={2}>El Salvador</option>
                                  <option value={3}>Honduras</option>
                                  <option value={4}>Nicaragua</option>
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                  <svg
                                    width={20}
                                    height={20}
                                    fill="none"
                                    stroke="#000000"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="m6 9 6 6 6-6" />
                                  </svg>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    {isSidebar === "Datos de Contacto" && (
                      <div className="flex flex-row flex-wrap items-center justify-start">
                        <h2 className="text-black text-left text-lg my-3 font-bold font-heading uppercase">
                          Datos de contacto
                        </h2>
                        <label
                          className="w-full block mb-2 text-sm text-black font-medium text-left uppercase mt-2 font-body"
                          htmlFor="name"
                        >
                          Nombre
                        </label>
                        <Input
                          className="p-3 mb-3"
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Nombre"
                          defaultValue={user?.name}
                        />
                        <label
                          className="w-full block mb-2 text-sm text-black font-medium text-left uppercase mt-2 font-body"
                          htmlFor="lastname"
                        >
                          Apellido
                        </label>
                        <Input
                          className="p-3 mb-3"
                          type="text"
                          id="lastname"
                          name="lastname"
                          placeholder="Apellido"
                          defaultValue={user?.lastname}
                        />

                        <label
                          className="w-full block mb-2 text-sm text-black font-medium text-left uppercase mt-2 font-body"
                          htmlFor="phone"
                        >
                          Whatsapp
                        </label>
                        <Input
                          className="p-3 mb-3"
                          type="number"
                          id="phone"
                          name="phone"
                          placeholder="whatsapp"
                          defaultValue={user?.phone}
                        />
                        <div className="flex flex-row w-full items-center justify-center space-x-2 my-2">
                          <Checkbox
                            id="whats_animated"
                            name="whats_animated"
                            defaultChecked={
                              animatedBehavior?.whats_animated ? true : false
                            }
                            onChange={(e) => {
                              setAnimatedBehavior({
                                whats_animated: e.target.checked,
                              });
                            }}
                            checked={
                              animatedBehavior?.whats_animated ? true : false
                            }
                            disabled={user?.template !== "promo"}
                          />
                          <label
                            className="w-full block text-sm text-black font-medium text-left uppercase font-body"
                            htmlFor="whats_animated"
                          >
                            Animación del icono
                            <strong className="text-sm text-black font-semibold uppercase ml-2">
                              {user?.template !== "promo" &&
                                "(Solo con tema Default)"}
                            </strong>
                          </label>
                        </div>
                        <label
                          className="w-full block mb-2 text-sm text-black font-medium text-left uppercase mt-2 font-body"
                          htmlFor="email"
                        >
                          Correo Electrónico
                        </label>
                        <Input
                          className="p-3 mb-3"
                          type="text"
                          id="email"
                          name="email"
                          placeholder="Correo Electrónico"
                          defaultValue={user?.email}
                        />
                        <label
                          className="w-full block mb-2 text-sm text-black font-medium text-left uppercase mt-2 font-body"
                          htmlFor="job"
                        >
                          Puesto de trabajo
                        </label>
                        <Input
                          className="p-3 mb-3"
                          type="text"
                          id="job"
                          name="job"
                          placeholder="Puesto de trabajo"
                          defaultValue={user?.job}
                        />
                        <label
                          className="mb-2 text-sm text-black font-medium text-left uppercase hidden"
                          htmlFor="gender"
                        >
                          Genero
                        </label>
                        <select
                          className="m-3 w-full rounded-lg p-3 focus:outline-none hidden"
                          id="gender"
                          name="gender"
                        >
                          <option value="male">Hombre</option>
                          <option value="female">Mujer</option>
                        </select>
                      </div>
                    )}
                    {isSidebar === "Documentos" && (
                      <>
                        <h2 className="text-black text-left text-lg my-3 font-bold font-heading uppercase">
                          Documentos
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          {/* documentos                                                                     */}
                          {user?.powerpoint ? (
                            <div className="border-black border-2 rounded-lg p-3 w-full flex justify-between items-center">
                              <div className="flex justify-between">
                                <img
                                  src={powerpoint_logo}
                                  alt="twitter"
                                  className="w-6 h-6"
                                />
                                <span className="ml-2 font-body">
                                  Power Point
                                </span>
                              </div>
                              <button
                                title="Borrar"
                                onClick={() => handleDeleteFile("powerpoint")}
                              >
                                <img src={trash} alt="eliminar" />
                              </button>
                            </div>
                          ) : (
                            <div>
                              <label
                                className="block mb-2 text-sm text-black font-medium text-left uppercase font-body"
                                htmlFor="file_input"
                              >
                                Subir Power Point (MAX. 2MB)
                              </label>
                              <span className="text-sm text-black font-medium uppercase">
                                {isBig && "El archivo es demasiado pesado"}
                              </span>
                              <Upload
                                showUploadList={false}
                                accept=".ppt,.pptx"
                                beforeUpload={handlePowePoint}
                                className="w-full flex"
                              >
                                <Button
                                  icon={<UploadOutlined />}
                                  className="bg-white w-full font-body"
                                >
                                  Seleccionar imagen
                                </Button>
                              </Upload>
                            </div>
                          )}
                          {user.word ? (
                            <div className="border-black border-2 rounded-lg p-3 w-full flex justify-between items-center">
                              <div className="flex justify-between">
                                <img
                                  src={word_logo}
                                  alt="twitter"
                                  className="w-6 h-6"
                                />
                                <span className="ml-2 font-body">Word</span>
                              </div>
                              <button
                                title="Borrar"
                                onClick={() => handleDeleteFile("word")}
                              >
                                <img src={trash} alt="eliminar" />
                              </button>
                            </div>
                          ) : (
                            <div>
                              <label
                                className="block mb-2 text-sm text-black font-medium text-left uppercase font-body"
                                htmlFor="file_input"
                              >
                                Subir Word (MAX. 2MB).
                              </label>
                              <span className="text-sm text-black font-medium uppercase">
                                {isBig && "El archivo es demasiado pesado"}
                              </span>
                              <Upload
                                showUploadList={false}
                                accept=".docx"
                                beforeUpload={handleWord}
                                className="w-full flex"
                              >
                                <Button
                                  icon={<UploadOutlined />}
                                  className="bg-white w-full font-body"
                                >
                                  Seleccionar imagen
                                </Button>
                              </Upload>
                            </div>
                          )}
                          {user.excel ? (
                            <div className="border-black border-2 rounded-lg p-3 w-full flex justify-between items-center">
                              <div className="flex justify-between">
                                <img
                                  src={excel_logo}
                                  alt="twitter"
                                  className="w-6 h-6"
                                />
                                <span className="ml-2 font-body">Excel</span>
                              </div>
                              <button
                                title="Borrar"
                                onClick={() => handleDeleteFile("excel")}
                              >
                                <img src={trash} alt="eliminar" />
                              </button>
                            </div>
                          ) : (
                            <div>
                              <label
                                className="block mb-2 text-sm text-black font-medium text-left uppercase font-body"
                                htmlFor="file_input"
                              >
                                Subir Excel (MAX. 2MB).
                              </label>
                              <span className="text-sm text-black font-medium uppercase">
                                {isBig && "El archivo es demasiado pesado"}
                              </span>
                              <Upload
                                showUploadList={false}
                                accept=".xlsx"
                                beforeUpload={handleExcel}
                                className="w-full flex"
                              >
                                <Button
                                  icon={<UploadOutlined />}
                                  className="bg-white w-full font-body"
                                >
                                  Seleccionar imagen
                                </Button>
                              </Upload>
                            </div>
                          )}
                          {user.pdf ? (
                            <div className="border-black border-2 rounded-lg p-3 w-full flex justify-between items-center">
                              <div className="flex justify-between">
                                <img
                                  src={pdf_logo}
                                  alt="twitter"
                                  className="w-6 h-6"
                                />
                                <span className="ml-2 font-body">PDF</span>
                              </div>
                              <button
                                title="Borrar"
                                onClick={() => handleDeleteFile("pdf")}
                              >
                                <img src={trash} alt="eliminar" />
                              </button>
                            </div>
                          ) : (
                            <div>
                              <label
                                className="block mb-2 text-sm text-black font-medium text-left uppercase font-body"
                                htmlFor="file_input"
                              >
                                Subir Pdf (MAX. 2MB).
                              </label>
                              <span className="text-sm text-black font-medium uppercase">
                                {isBig && "El archivo es demasiado pesado"}
                              </span>
                              <Upload
                                showUploadList={false}
                                accept=".pdf"
                                beforeUpload={handlePdf}
                                className="w-full flex"
                              >
                                <Button
                                  icon={<UploadOutlined />}
                                  className="bg-white w-full font-body"
                                >
                                  Seleccionar imagen
                                </Button>
                              </Upload>
                            </div>
                          )}
                        </div>
                        {/* DOCUMENTOS POR CARGAR */}
                      </>
                    )}
                    {isSidebar === "Redes Sociales" && (
                      <>
                        {Object.values(socials).some((value) => value) && (
                          <h2 className="text-black text-left text-lg my-3 font-bold font-heading uppercase">
                            Redes Sincronizadas
                          </h2>
                        )}
                        {Object.values(socials).every(
                          (value) =>
                            value === null ||
                            value === undefined ||
                            value === ""
                        ) && (
                          <h2 className="text-black text-left text-lg my-3 font-bold font-heading uppercase">
                            Redes Sociales (Opcionales)
                          </h2>
                        )}
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          {socials.web && (
                            <CardSocial
                              logo={web_logo}
                              titulo="Sitio Web"
                              url={
                                socials.web.includes("https://")
                                  ? socials.web.replace("https://", "")
                                  : socials.web
                              }
                              handEvent={() => handleDelete("web")}
                            />
                          )}
                          {socials.linkedin && (
                            <CardSocial
                              logo={linkedid_logo}
                              titulo="LinkedIn"
                              url={
                                socials.linkedin.includes(
                                  "https://www.linkedin.com/in/"
                                )
                                  ? socials.linkedin.replace(
                                      "https://www.linkedin.com/in/",
                                      ""
                                    )
                                  : socials.linkedin
                              }
                              handEvent={() => handleDelete("linkedin")}
                            />
                          )}
                          {socials.twitter && (
                            <CardSocial
                              logo={xlogo}
                              titulo="Twitter"
                              url={
                                socials.twitter.includes("https://twitter.com/")
                                  ? socials.twitter.replace(
                                      "https://twitter.com/",
                                      "@"
                                    )
                                  : socials.twitter
                              }
                              handEvent={() => handleDelete("twitter")}
                            />
                          )}
                          {socials.instagram && (
                            <CardSocial
                              logo={instagram_logo}
                              titulo="Instagram"
                              url={
                                socials.instagram.includes(
                                  "https://www.instagram.com/"
                                )
                                  ? socials.instagram.replace(
                                      "https://www.instagram.com/",
                                      "@"
                                    )
                                  : socials.instagram
                              }
                              handEvent={() => handleDelete("instagram")}
                            />
                          )}
                          {socials.facebook && (
                            <CardSocial
                              logo={facebook}
                              titulo="Facebook"
                              url={
                                socials.facebook.includes(
                                  "https://www.facebook.com/"
                                )
                                  ? socials.facebook.replace(
                                      "https://www.facebook.com/",
                                      ""
                                    )
                                  : socials.facebook
                              }
                              handEvent={() => handleDelete("facebook")}
                            />
                          )}
                          {socials.video && (
                            <CardSocial
                              logo={video_logo}
                              titulo="Video"
                              url={
                                socials.video.includes(
                                  "https://www.youtube.com/watch?v="
                                )
                                  ? socials.video.replace(
                                      "https://www.youtube.com/watch?v=",
                                      ""
                                    )
                                  : socials.video
                              }
                              handEvent={() => handleDelete("video")}
                            />
                          )}
                          {socials.microsoftteams && (
                            <CardSocial
                              logo={msteams_logo}
                              titulo="Microsoft Teams"
                              url={
                                socials.microsoftteams.includes(
                                  "https://www.youtube.com/watch?v="
                                )
                                  ? socials.microsoftteams.replace(
                                      "https://www.youtube.com/watch?v=",
                                      ""
                                    )
                                  : socials.microsoftteams
                              }
                              handEvent={() => handleDelete("microsoftteams")}
                            />
                          )}
                          {socials.augmented_reality &&
                          (user?.projectId === "bh" ||
                            user?.projectId === "promo") ? (
                            <CardSocial
                              logo={ra_logo}
                              titulo="Realidad Aumentada"
                              url={socials.augmented_reality}
                              handEvent={() =>
                                handleDelete("augmented_reality")
                              }
                            />
                          ) : null}
                        </div>
                        {/* REDES SOCIALES POR ASOCIAR */}
                        {socials.web ? null : (
                          <div className="rounded-lg py-2 w-full">
                            <label
                              className="block mb-2 text-sm text-black font-medium text-left uppercase font-body"
                              htmlFor="web"
                            >
                              Sitio Web
                            </label>
                            <Input
                              className="py-3 px-3 mb-1"
                              type="text"
                              id="web"
                              name="web"
                              placeholder="Sitio Web"
                              defaultValue={socials.web}
                            />
                            <p className="mx-3 text-black text-[12px] text-left">
                              Agregar el url completo. Por ejemplo:
                              https://www.misitio.com
                            </p>
                          </div>
                        )}
                        {socials.video ? null : (
                          <div className="rounded-lg py-2 w-full">
                            <label
                              className="block mb-2 text-sm text-black font-medium text-left uppercase font-body"
                              htmlFor="video"
                            >
                              Video
                            </label>
                            <Input
                              className="py-3 px-3 mb-1"
                              type="text"
                              id="video"
                              name="video"
                              placeholder="URL del video"
                              defaultValue={socials.video}
                            />
                            <p className="mx-3 text-black text-[12px] text-left">
                              Agregar el url completo.
                            </p>
                          </div>
                        )}
                        {socials.linkedin ? null : (
                          <div className="rounded-lg py-2 w-full">
                            <label
                              className="block mb-2 text-sm text-black font-medium text-left uppercase font-body"
                              htmlFor="linkedin"
                            >
                              LinkedIn
                            </label>
                            <Input
                              className="py-3 px-3 mb-1"
                              type="text"
                              id="linkedin"
                              name="linkedin"
                              placeholder="Usuario de LinkedIn"
                              defaultValue={socials.linkedin}
                            />
                            <p className="mx-3 text-black text-[12px] text-left">
                              Agregar únicamente el usuario, ejemplo:
                              <br />
                              De la dirección{" "}
                              <span className="font-bold">
                                linkedin.com/in/juanlopez.sanchez
                              </span>{" "}
                              únicamente ingresar{" "}
                              <span className="font-bold">
                                juanlopez.sanchez
                              </span>
                            </p>
                          </div>
                        )}
                        {socials.twitter ? null : (
                          <div className="rounded-lg py-2 w-full">
                            <label
                              className="block mb-2 text-sm text-black font-medium text-left uppercase font-body"
                              htmlFor="twitter"
                            >
                              X (Twitter)
                            </label>
                            <Input
                              className="py-3 px-3 mb-1"
                              type="text"
                              id="twitter"
                              name="twitter"
                              placeholder="Usuario de Twitter"
                              defaultValue={socials.twitter}
                            />
                            <p className="mx-3 text-black text-[12px] text-left">
                              Agregar únicamente el usuario, ejemplo:
                              <br />
                              De la dirección{" "}
                              <span className="font-bold">
                                twitter.com/juanlopez.sanchez
                              </span>{" "}
                              únicamente ingresar{" "}
                              <span className="font-bold">
                                juanlopez.sanchez
                              </span>
                            </p>
                          </div>
                        )}
                        {socials.instagram ? null : (
                          <div className="rounded-lg py-2 w-full">
                            <label
                              className="block mb-2 text-sm text-black font-medium text-left uppercase font-body"
                              htmlFor="instagram"
                            >
                              Instagram
                            </label>
                            <Input
                              className="py-3 px-3 mb-1"
                              type="text"
                              id="instagram"
                              name="instagram"
                              placeholder="Usuario de Instagram"
                              defaultValue={socials.instagram}
                            />
                            <p className="mx-3 text-black text-[12px] text-left">
                              Agregar únicamente el usuario, ejemplo:
                              <br />
                              De la dirección{" "}
                              <span className="font-bold">
                                instagram.com/juanlopez.sanchez
                              </span>{" "}
                              únicamente ingresar{" "}
                              <span className="font-bold">
                                juanlopez.sanchez
                              </span>
                            </p>
                          </div>
                        )}
                        {socials.facebook ? null : (
                          <div className="rounded-lg py-2 w-full">
                            <label
                              className="block mb-2 text-sm text-black font-medium text-left uppercase font-body"
                              htmlFor="facebook"
                            >
                              Facebook
                            </label>
                            <Input
                              className="py-3 px-3 mb-1"
                              type="text"
                              id="facebook"
                              name="facebook"
                              placeholder="Usuario de Facebook"
                              defaultValue={socials.facebook}
                            />
                            <p className="mx-3 text-black text-[12px] text-left">
                              Agregar únicamente el usuario, ejemplo:
                              <br />
                              De la dirección{" "}
                              <span className="font-bold">
                                facebook.com/juanlopez.sanchez
                              </span>{" "}
                              únicamente ingresar{" "}
                              <span className="font-bold">
                                juanlopez.sanchez
                              </span>
                            </p>
                          </div>
                        )}
                        {socials.microsoftteams ? null : (
                          <div className="rounded-lg py-2 w-full">
                            <label
                              className="block mb-2 text-sm text-black font-medium text-left uppercase font-body"
                              htmlFor="microsoftteams"
                            >
                              Microsoft Teams
                            </label>
                            <Input
                              className="py-3 px-3 mb-1"
                              type="text"
                              id="microsoftteams"
                              name="microsoftteams"
                              placeholder="URL de Microsoft Teams"
                              defaultValue={socials.microsoftteams}
                            />
                            <p className="mx-3 text-black text-[12px] text-left">
                              Agregar únicamente el usuario, ejemplo:
                              <br />
                              De la dirección{" "}
                              <span className="font-bold">
                                teams.microsoft.com/l/chat/0/0?users=user_email
                              </span>{" "}
                              únicamente ingresar{" "}
                              <span className="font-bold">
                                chat/0/0?users=user_email
                              </span>
                            </p>
                          </div>
                        )}
                        {!socials.augmented_reality &&
                        (projectId === "bh" || projectId === "promo") ? (
                          <div className="rounded-lg py-2 w-full">
                            <label
                              className="block mb-2 text-sm text-black font-medium text-left uppercase font-body"
                              htmlFor="augmented_reality"
                            >
                              Realidad Aumentada
                            </label>
                            <Input
                              className="py-3 px-3 mb-1"
                              type="text"
                              id="augmented_reality"
                              name="augmented_reality"
                              placeholder="URL de Microsoft Teams"
                              defaultValue={socials.augmented_reality}
                            />
                            <p className="mx-3 text-black text-[12px] text-left">
                              Agregar el url completo.
                            </p>
                          </div>
                        ) : null}
                      </>
                    )}
                    <motion.button
                      whileHover={{ scale: 1.03 }}
                      whileTap={{ scale: 0.9 }}
                      transition={{
                        type: "spring",
                        stiffness: 400,
                        damping: 17,
                      }}
                      className={`${
                        isSidebar === "Documentos"
                          ? "hidden"
                          : "bg-black text-white font-bold py-2 px-4 rounded-lg uppercase focus:outline-none font-body w-full my-5"
                      } `}
                      type="submit"
                    >
                      Guardar Cambios
                    </motion.button>
                  </form>
                </div>
              </motion.div>
            </>
          ) : (
            <div className="md:w-9/12 sm:w-full sm:p-4 md:left-1/2 md:-translate-x-2/4 sm:top-0 sm:-translate-x-0 text-center mt-5 mb-5 absolute">
              <AnimatePresence>
                <div className="md:w-4/12 sm:w-auto top-full left-1/2 -translate-x-2/4 translate-y-full text-center absolute">
                  <motion.img
                    initial={{ rotateY: 0 }}
                    animate={{ rotateY: 360 }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      type: "tween",
                      repeatDelay: 0,
                    }}
                    exit={{ opacity: 0 }}
                    src={logo}
                    alt="logo makinn"
                    className="mx-auto mb-5"
                    width={150}
                    height={150}
                  />
                  <p className="uppercase text-lg">Cargando</p>
                </div>
              </AnimatePresence>
            </div>
          )
        ) : (
          <div className="md:w-9/12 sm:w-full sm:p-4 md:left-1/2 md:-translate-x-2/4 sm:top-0 sm:-translate-x-0 text-center mt-5 mb-5 absolute">
            <div className="w-full h-screen flex flex-col justify-center items-center">
              <h1 className="text-black text-center text-xl mb-5 font-bold">
                Necesitas iniciar sesión
              </h1>
              <Link
                className="bg-makinn_primary rounded-xl py-3 px-5 font-bold uppercase text-white"
                to={`/${projectId}`}
              >
                Ir ahora
              </Link>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default EditProfile;
